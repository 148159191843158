<template>
  <div>
    <form class="input-form" @submit.prevent="onSubmit">
      <p>
        <label for="name">Name:</label>
        <input id="name" v-model="name" placeholder="name">
      </p>
      
      <p>
        <label for="comment">Comment:</label>      
        <textarea id="comment" v-model="comment"></textarea>
      </p>
          
      <p>
        <input type="submit" value="Submit">  
      </p>   
    </form>
  </div>
</template>

<script>
export default {
  name: 'InputForm',
  data() {
      return {
        name: null,
        comment: null
      }
    }, 
    methods: {
    onSubmit() {
      let submittedComment = {
        name: this.name,
        comment: this.comment
      }
      this.name = null
      this.comment = null
      return submittedComment
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>