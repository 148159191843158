<template>
  <div class="hello">
    <h1 data-test='title'>{{ msg }}</h1>
    <div>
      <h3>Mirage List</h3>
      <ul id='lists'>
        <li v-for='item in lists' v-bind:key='item.id'>{{ item.text }}</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  data() {
    return {
    lists: []
    }
  },
  props: {
    msg: String
  }, 
  created() {
    fetch('/api/lists')
    .then((res) => res.json())
    .then((json) => {
      this.lists = json.lists;
    })
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
